var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("LandingHeader"),
      _c("SupportHero"),
      _c("SupportHelp"),
      _c("div", { ref: "featuresSection", staticClass: "features-section" }, [
        !_vm.ticketType
          ? _c("div", { ref: "featuresCol", staticClass: "features-col" }, [
              _c(
                "div",
                {
                  staticClass: "feature-box feature-box-hover",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      _vm.ticketType = "Technical Support"
                    },
                  },
                },
                [_vm._m(0)]
              ),
              _c(
                "div",
                {
                  staticClass: "feature-box feature-box-hover",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      _vm.ticketType = "Improvement"
                    },
                  },
                },
                [_vm._m(1)]
              ),
              _c(
                "div",
                {
                  staticClass: "feature-box feature-box-hover",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      _vm.ticketType = "General"
                    },
                  },
                },
                [_vm._m(2)]
              ),
            ])
          : _c("div", { staticClass: "features-col-form" }, [
              _vm.ticketType === "Technical Support"
                ? _c(
                    "div",
                    {
                      staticClass: "feature-box",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          _vm.ticketType = null
                        },
                      },
                    },
                    [_vm._m(3)]
                  )
                : _vm.ticketType === "Improvement"
                ? _c(
                    "div",
                    {
                      staticClass: "feature-box",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          _vm.ticketType = null
                        },
                      },
                    },
                    [_vm._m(4)]
                  )
                : _vm.ticketType === "General"
                ? _c(
                    "div",
                    {
                      staticClass: "feature-box",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          _vm.ticketType = null
                        },
                      },
                    },
                    [_vm._m(5)]
                  )
                : _vm._e(),
              _c("div", { staticClass: "align-self-center ml-5" }, [
                _c("div", { staticStyle: { width: "450px" } }, [
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(6),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.name,
                          expression: "form.name",
                        },
                      ],
                      staticClass: "form-control form-control-lg",
                      attrs: { type: "text", placeholder: "Name" },
                      domProps: { value: _vm.form.name },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "name", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("div", { staticClass: "col-lg-6" }, [
                      _vm._m(7),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.email,
                            expression: "form.email",
                          },
                        ],
                        staticClass: "form-control form-control-lg",
                        attrs: { type: "email", placeholder: "Email Address" },
                        domProps: { value: _vm.form.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "email", $event.target.value)
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "col-lg-6" }, [
                      _vm._m(8),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.phoneNumber,
                            expression: "form.phoneNumber",
                          },
                        ],
                        staticClass: "form-control form-control-lg",
                        attrs: {
                          type: "tel",
                          placeholder: "Phone Number (optional)",
                        },
                        domProps: { value: _vm.form.phoneNumber },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.form,
                              "phoneNumber",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "form-group" }, [
                    _vm._m(9),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.schoolDBN,
                          expression: "form.schoolDBN",
                        },
                      ],
                      staticClass: "form-control form-control-lg",
                      attrs: { type: "text", placeholder: "School DBN" },
                      domProps: { value: _vm.form.schoolDBN },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "schoolDBN", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "form-group form-group-last" }, [
                    _vm._m(10),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.message,
                          expression: "form.message",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: { id: "message", rows: "4" },
                      domProps: { value: _vm.form.message },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form, "message", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "kt-form__actions float-right mt-3" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary mr-2",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.clearForm.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-bold",
                          class: {
                            "kt-spinner kt-spinner--sm kt-spinner--light":
                              _vm.saving,
                          },
                          attrs: { disabled: _vm.saving || !_vm.canSubmitForm },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.submitForm.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v(" Submit ")]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
      ]),
      _c("LandingFooter"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-one" }, [
      _c("img", {
        attrs: { src: "/images/landing/support-icons_1.png", alt: "option" },
      }),
      _c("h3", [_vm._v("Technical Support")]),
      _c("p", [
        _vm._v(
          " I am having trouble and ran into an issue while using SyncGrades "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-one" }, [
      _c("img", {
        attrs: {
          id: "bulb-option",
          src: "/images/landing/support-icons_2.png",
          alt: "option",
        },
      }),
      _c("h3", [_vm._v("Improvement + New Features")]),
      _c("p", [_vm._v("I Have an idea that could enhance SyncGrades")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-one" }, [
      _c("img", {
        attrs: { src: "/images/landing/support-icons_3.png", alt: "option" },
      }),
      _c("h3", [_vm._v("General Information and Questions")]),
      _c("p", [
        _vm._v(
          " I have a general inquiry or questions not related to the other topic "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-one" }, [
      _c("img", {
        attrs: { src: "/images/landing/support-icons_1.png", alt: "option" },
      }),
      _c("h3", [_vm._v("Technical Support")]),
      _c("p", [
        _vm._v(
          " I am having trouble and ran into an issue while using SyncGrades "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-one" }, [
      _c("img", {
        attrs: {
          id: "bulb-option",
          src: "/images/landing/support-icons_2.png",
          alt: "option",
        },
      }),
      _c("h3", [_vm._v("Improvement + New Features")]),
      _c("p", [_vm._v("I Have an idea that could enhance SyncGrades")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-one" }, [
      _c("img", {
        attrs: { src: "/images/landing/support-icons_3.png", alt: "option" },
      }),
      _c("h3", [_vm._v("General Information and Questions")]),
      _c("p", [
        _vm._v(
          " I have a general inquiry or questions not related to the other topic "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "kt-font-lg" }, [
      _c("i", { staticClass: "fa fa-user mr-2" }),
      _vm._v("Full Name "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "kt-font-lg" }, [
      _c("i", { staticClass: "fa fa-envelope mr-2" }),
      _vm._v("Email "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "kt-font-lg" }, [
      _c("i", { staticClass: "fa fa-phone-alt mr-2" }),
      _vm._v("Phone Number "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "kt-font-lg" }, [
      _c("i", { staticClass: "fa fa-id-card mr-2" }),
      _vm._v("DBN "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "kt-font-lg", attrs: { for: "message" } },
      [
        _c("i", { staticClass: "fa fa-comment-alt mr-2" }),
        _vm._v("Tell us more "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }