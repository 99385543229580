var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "aboutSection", staticClass: "about-section" }, [
    _c("div", { ref: "aboutCol", staticClass: "about-col" }, [
      _vm._m(0),
      _vm._m(1),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "first-col" }, [
      _c("h2", [_vm._v("How Can We Help?")]),
      _c("p", [
        _vm._v(
          " Please select a topic most closely related to your issue or inquiry "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "second-col" }, [
      _c("img", {
        attrs: {
          src: "/images/landing/about-image.png",
          alt: "feature",
          width: "100%",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }