<template>
<div id="app">
    <!-- header new -->
    <LandingHeader />

    <!-- hero section -->
    <SupportHero />

    <!-- <LandingAbout /> -->
    <SupportHelp />

    <!-- cards -->
    <div ref="featuresSection" class="features-section">
        <div
            v-if="!ticketType"
            ref="featuresCol"
            class="features-col"
        >
            <div class="feature-box feature-box-hover" @click.stop.prevent="ticketType = 'Technical Support'">
                <div class="col-one">
                    <img src="/images/landing/support-icons_1.png" alt="option">
                    <h3>Technical Support</h3>
                    <p>
                        I am having trouble and ran into an issue while using SyncGrades
                    </p>
                </div>
            </div>
            <div class="feature-box feature-box-hover" @click.stop.prevent="ticketType = 'Improvement'">
                <div class="col-one">
                    <img
                        id="bulb-option"
                        src="/images/landing/support-icons_2.png"
                        alt="option"
                    >
                    <h3>Improvement + New Features</h3>
                    <p>I Have an idea that could enhance SyncGrades</p>
                </div>
            </div>
            <div class="feature-box feature-box-hover" @click.stop.prevent="ticketType = 'General'">
                <div class="col-one">
                    <img src="/images/landing/support-icons_3.png" alt="option">
                    <h3>General Information and Questions</h3>
                    <p>
                        I have a general inquiry or questions not related to the other
                        topic
                    </p>
                </div>
            </div>
        </div>
        <div v-else class="features-col-form">
            <div
                v-if="ticketType === 'Technical Support'"
                class="feature-box"
                @click.stop.prevent="ticketType = null"
            >
                <div class="col-one">
                    <img src="/images/landing/support-icons_1.png" alt="option">
                    <h3>Technical Support</h3>
                    <p>
                        I am having trouble and ran into an issue while using SyncGrades
                    </p>
                </div>
            </div>
            <div
                v-else-if="ticketType === 'Improvement'"
                class="feature-box"
                @click.stop.prevent="ticketType = null"
            >
                <div class="col-one">
                    <img
                        id="bulb-option"
                        src="/images/landing/support-icons_2.png"
                        alt="option"
                    >
                    <h3>Improvement + New Features</h3>
                    <p>I Have an idea that could enhance SyncGrades</p>
                </div>
            </div>
            <div
                v-else-if="ticketType === 'General'"
                class="feature-box"
                @click.stop.prevent="ticketType = null"
            >
                <div class="col-one">
                    <img src="/images/landing/support-icons_3.png" alt="option">
                    <h3>General Information and Questions</h3>
                    <p>
                        I have a general inquiry or questions not related to the other
                        topic
                    </p>
                </div>
            </div>
            <div class="align-self-center ml-5">
                <div style="width: 450px;">
                    <div class="form-group">
                        <label class="kt-font-lg">
                            <i class="fa fa-user mr-2" />Full Name
                        </label>
                        <input
                            v-model="form.name"
                            type="text"
                            class="form-control form-control-lg"
                            placeholder="Name"
                        >
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label class="kt-font-lg">
                                <i class="fa fa-envelope mr-2" />Email
                            </label>
                            <input
                                v-model="form.email"
                                type="email"
                                class="form-control form-control-lg"
                                placeholder="Email Address"
                            >
                        </div>
                        <div class="col-lg-6">
                            <label class="kt-font-lg">
                                <i class="fa fa-phone-alt mr-2" />Phone Number
                            </label>
                            <input
                                v-model="form.phoneNumber"
                                type="tel"
                                class="form-control form-control-lg"
                                placeholder="Phone Number (optional)"
                            >
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="kt-font-lg">
                            <i class="fa fa-id-card mr-2" />DBN
                        </label>
                        <input
                            v-model="form.schoolDBN"
                            type="text"
                            class="form-control form-control-lg"
                            placeholder="School DBN"
                        >
                    </div>
                    <div class="form-group form-group-last">
                        <label for="message" class="kt-font-lg">
                            <i class="fa fa-comment-alt mr-2" />Tell us more
                        </label>
                        <textarea
                            id="message"
                            v-model="form.message"
                            class="form-control"
                            rows="4"
                        />
                    </div>
                    <div class="kt-form__actions float-right mt-3">
                        <button
                            class="btn btn-secondary mr-2"
                            @click.stop.prevent="clearForm"
                        >
                            Cancel
                        </button>
                        <button
                            class="btn btn-primary btn-bold"
                            :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                            :disabled="saving || !canSubmitForm"
                            @click.stop.prevent="submitForm"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- footer-section -->
    <LandingFooter />
</div>
</template>

<script>
import gsap from 'gsap';
import LandingHeader from '../components/landing/LandingHeader.vue';
import SupportHero from '../components/landing/SupportHero.vue';
import SupportHelp from '../components/landing/SupportHelp.vue';
import LandingFooter from '../components/landing/LandingFooter.vue';
import * as network from '../network';

export default {
    name: 'TechSupport',
    components: {
        LandingHeader,
        SupportHero,
        SupportHelp,
        LandingFooter,
    },
    data() {
        return {
            ticketType: null,
            form: {
                name: null,
                email: null,
                phoneNumber: null,
                schoolDBN: null,
                message: null,
            },
            saving: false,
            recentlySubmitted: false,
        };
    },
    computed: {
        canSubmitForm() {
            const { ticketType, form } = this;
            return ticketType && form.name && form.email && form.schoolDBN && form.message;
        },
    },
    mounted() {
        // Intersection Observer to detect when element enters viewport
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Trigger animation when element enters viewport
                    this.animateHeading();
                    observer.unobserve(entry.target);
                }
            });
        });

        // Observe the hero section
        observer.observe(this.$refs.featuresSection);
    },
    methods: {
        animateHeading() {
            // GSAP Animation
            gsap.from(this.$refs.featuresCol, {
                duration: 1,
                y: 150,
                opacity: 0,
                delay: 1,
                ease: 'power1.inOut',
            });
        },
        clearForm() {
            this.form = {
                name: null,
                email: null,
                phoneNumber: null,
                schoolDBN: null,
                message: null,
            };
            this.ticketType = null;
        },
        submitForm() {
            const v = this;
            if (v.recentlySubmitted) {
                return v.showError('We got your last form! Please wait a moment before submitting the form again.');
            }
            if (v.saving || !v.canSubmitForm) return;
            v.saving = true;

            const { form, ticketType } = v;
            const {
                name, email, phoneNumber, schoolDBN, message,
            } = form;

            const params = {
                name,
                email,
                phoneNumber,
                ticketType,
                schoolDBN,
                message,
            };

            network.landing.submitSupportTicket({ body: { params } }, (err) => {
                v.saving = false;
                if (err) return v.showError(err);

                v.showNotification('Your ticket has been submitted successfully!');
                v.clearForm();
                v.recentlySubmitted = true;
                setTimeout(() => {
                    v.recentlySubmitted = false;
                }, 10000);
            });
        },
    },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.features-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-bottom: 90px;

  /* background: #f5f5f5; */

  .features-col {
    width: 1200px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .feature-box {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      background: #ebefff;
      border-radius: 1em;
      box-shadow: 0 10px 20px #131a331e;
      margin: 20px;
      height: 400px;
      cursor: pointer;
      transition: 0.5s;

      .col-one {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h3 {
          text-align: center;
          font-size: 2em;
          font-weight: 700;
          margin-bottom: 20px;
        }

        p {
          text-align: center;
          font-size: 1.2em;
          font-weight: 500;
          color: #666;
          margin-bottom: 20px;
        }
        img {
          width: 80px;
          margin-bottom: 20px;
        }
      }
    }

    .feature-box-hover:hover {
        margin-top: -10px;
    }
  }

  .features-col-form {
    width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .feature-box {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      background: #ebefff;
      border-radius: 1em;
      box-shadow: 0 10px 20px #131a331e;
      margin: 20px;
      height: 480px;
      cursor: pointer;
      transition: 0.5s;
      width: 360px;

      .col-one {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h3 {
          text-align: center;
          font-size: 2em;
          font-weight: 700;
          margin-bottom: 20px;
        }

        p {
          text-align: center;
          font-size: 1.2em;
          font-weight: 500;
          color: #666;
          margin-bottom: 20px;
        }
        img {
          width: 80px;
          margin-bottom: 20px;
        }
      }
    }

    .feature-box-hover:hover {
        margin-top: -10px;
    }
  }
}

#bulb-option {
  width: 50px;
}

@media screen and (max-width: 1024px) {
  .features-section {
    .features-col {
      width: 100%;
      .feature-box {
        .col-one {
          h3 {
            font-size: 1.8em;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .features-section {
    .features-col {
      grid-template-columns: repeat(1, 1fr);
      .feature-box {
        flex-direction: column;
        padding: 20px;
        height: auto;
        .col-one {
          width: 100%;
          h3 {
            font-size: 2em;
          }
          img {
            width: 80px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 425px) {
  .features-section {
    .features-col {
      flex-direction: column;
      .feature-box {
        flex-direction: column-reverse;
        padding: 20px;
        height: auto;
        .col-one {
          width: 100%;
          h3 {
            font-size: 2em;
          }
          img {
            width: 30%;
          }
        }
      }
    }
  }
}
</style>
